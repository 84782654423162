import React from 'react';
import { Field, CccisdInput, CccisdTextarea } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    greenMin: 17,
    greenMax: 27,
    greenLabel: 'No STS',
    greenText:
        'Consider retaking My STAT Level at the end of this school year, or sooner if you have a secondary traumatic experience with a student or someone else. Even though you don’t currently have symptoms of STS, we recommend that you look at module 5 to educate yourself on helpful self-care techniques.',
    blueMin: 28,
    blueMax: 37,
    blueLabel: 'Mild STS',
    blueText:
        'Consider retaking My STAT Level at the end of this school year, or sooner if you have a secondary traumatic experience with a student or someone else. In addition, we recommend exploring module 5 to learn about self-care strategies.',
    yellowMin: 38,
    yellowMax: 43,
    yellowLabel: 'Moderate STS',
    yellowText:
        'Consider monitoring your My STAT Level status at least three times this school year.You may also benefit from counseling and self-care activities. See module 5 for a discussion of self-care techniques.',
    orangeMin: 44,
    orangeMax: 48,
    orangeLabel: 'High STS',
    orangeText:
        'Consider monitoring your My STAT Level status at least three times this school year, for example, at the beginning or end of each quarter. Also explore module 5 for information on self-care activities, which may help alleviate some of your symptoms of STS. Additionally, you would almost certainly benefit from seeking counseling for additional support.',
    redMin: 49,
    redMax: 85,
    redLabel: 'Severe STS',
    redText:
        'Consider monitoring your My STAT Level status at least three times this school year. This could be at the beginning or end of each quarter or at other regular intervals that map to natural breaks in your school’s schedule. Additionally, please visit module 5 to learn about self-care strategies and develop a personalized self-care plan. We also recommend seeking mental health care as soon as possible.',
};

const Component = props => {
    const colorDiv = { display: 'flex', flexDirection: 'row', gap: '1em' };

    return (
        <>
            <div>
                <h3>Green</h3>
                <div style={colorDiv}>
                    <Field name="greenMin" type="number" component={CccisdInput} label="Green Min" />
                    <Field name="greenMax" type="number" component={CccisdInput} label="Green Max" />
                </div>
                <Field name="greenLabel" component={CccisdInput} label="Green Label" />
                <Field name="greenText" component={CccisdTextarea} label="Green Text" />
            </div>
            <div>
                <h3>Blue</h3>
                <div style={colorDiv}>
                    <Field name="blueMin" type="number" component={CccisdInput} label="Blue Min" />
                    <Field name="blueMax" type="number" component={CccisdInput} label="Blue Max" />
                </div>
                <Field name="blueLabel" component={CccisdInput} label="Blue Label" />
                <Field name="blueText" component={CccisdTextarea} label="Blue Text" />
            </div>
            <div>
                <h3>Yellow</h3>
                <div style={colorDiv}>
                    <Field name="yellowMin" type="number" component={CccisdInput} label="Yellow Min" />
                    <Field name="yellowMax" type="number" component={CccisdInput} label="Yellow Max" />
                </div>
                <Field name="yellowLabel" component={CccisdInput} label="Yellow Label" />
                <Field name="yellowText" component={CccisdTextarea} label="Yellow Text" />
            </div>
            <div>
                <h3>Orange</h3>
                <div style={colorDiv}>
                    <Field name="orangeMin" type="number" component={CccisdInput} label="Orange Min" />
                    <Field name="orangeMax" type="number" component={CccisdInput} label="Orange Max" />
                </div>
                <Field name="orangeLabel" component={CccisdInput} label="Orange Label" />
                <Field name="orangeText" component={CccisdTextarea} label="Orange Text" />
            </div>
            <div>
                <h3>Red</h3>
                <div style={colorDiv}>
                    <Field name="redMin" type="number" component={CccisdInput} label="Red Min" />
                    <Field name="redMax" type="number" component={CccisdInput} label="Red Max" />
                </div>
                <Field name="redLabel" component={CccisdInput} label="Red Label" />
                <Field name="redText" component={CccisdTextarea} label="Red Text" />
            </div>
        </>
    );
};

// widgetBuilder is a Formik Wrapper.
export default widgetBuilder({ initialValues })(Component);
