import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import ReportHeader from '../../common/LIFT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/LIFT_Report_Footer/index.js';
import { renderAlert, shortenResponse } from '../../common/reportHelpers.js';
import responsesQuery from './responses.graphql';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);
    const [reportSettings, setReportSettings] = useState({
        heading: '',
        story: '',
    });
    const reportTitle = props.settings?.chapterTitle;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const responses = response.data.flows.assignmentProgress.devTags;

        if (!responses.Chapter5Story && !responses.Chapter5Heading) {
            setHasModuleProgress(false);
        } else {
            setHasModuleProgress(true);
        }

        setReportSettings({
            heading: shortenResponse(responses.Chapter5Heading, 60),
            story: shortenResponse(responses.Chapter5Story, 1050),
        });

        setLoading(false);
    }

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <Report downloadFilename={`LIFT_Chapter_5_${reportTitle}_Report`}>
            <Page>
                <div className={style.flexWrapper}>
                    <ReportHeader chapterId="5" chapterTitle={reportTitle} />
                    {hasModuleProgress ? (
                        <div className={style.body}>
                            <p>
                                If you&apos;d like to save or print a copy of your story, please do so now. Your story
                                will be erased when you close this browser and will not be saved to your binder. You can
                                use the box below to draw a picture to go with your story if desired.
                            </p>
                            <div className={style.drawing} />
                            <h3>{reportSettings.heading}</h3>
                            <div className={style.story}>{reportSettings.story}</div>
                        </div>
                    ) : (
                        renderAlert('No responses were recorded yet.')
                    )}

                    <ReportFooter />
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

export default Component;
