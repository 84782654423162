import React from 'react';
import PropTypes from 'prop-types';

import style from './style.css';
import BarChart from './BarChart';
import HorizontalBarChart from './HorizontalBarChart';
import { renderAlert } from '../../../common/reportHelpers.js';

const Timepoint = props => {
    const { timepoint, level } = props;

    if (timepoint.complete === false) {
        return (
            <div className={style.timepoint}>
                <div className={style.completedDate}>
                    <div className={style.id}>{timepoint.id}</div>
                </div>
                <div id={style.incomplete}>
                    <div className={style.banner}>{renderAlert('This timepoint has not been completed')}</div>
                </div>
            </div>
        );
    }

    const text = level.text.split('\n');

    return (
        <div className={style.timepoint} id={style.complete}>
            <div className={style.completedDate}>
                <div className={style.id}>{timepoint.id}</div>
                <div>
                    <div>Date Complete</div>
                    <div>
                        <strong>{timepoint.completedDate}</strong>
                    </div>
                </div>
            </div>

            <div className={style.total}>
                <h5>
                    STS Level Total: <span style={{ color: level.hex }}>{timepoint.STATTotal}</span>
                </h5>
                <HorizontalBarChart total={timepoint.STATTotal} color={level.hex} />
            </div>
            <div className={style.componentLevel}>
                <h5>Component Levels</h5>
                <BarChart timepoint={timepoint} />
            </div>
            <div className={style.summary}>
                <h5>
                    <span style={{ color: level.hex, textTransform: 'uppercase' }}>{level.color}</span>: {level.label}
                </h5>
                <div className={style.text}>
                    {text.map((txt, i) => (
                        <p key={i}>{txt}</p>
                    ))}
                </div>
            </div>
        </div>
    );
};

Timepoint.propTypes = {
    timepoint: PropTypes.object,
    level: PropTypes.object,
};

export default Timepoint;
