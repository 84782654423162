import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import { Report, Page } from 'cccisd-laravel-report';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';

import widgets from '../widgets/widgets.js';
import ReportHeader from '../../common/STAT_Report_Header/index.js';
import style from './style.css';
import ReportFooter from '../../common/STAT_Report_Footer/index.js';
import responsesQuery from './responses.graphql';
import { renderAlert } from '../../common/reportHelpers.js';
import bag from '../../images/STAT/m2/bag.png';
import dna from '../../images/STAT/m2/dna.png';
import note from '../../images/STAT/m2/M2_Factors Relevant to Me.png';
import exclamation from '../../images/STAT/m2/exclamation.png';

const Fortress = window.cccisd && window.cccisd.fortress;
const pawnId = Fortress.user.acting.id;

const Component = props => {
    const [reportSettings, setReportSettings] = useState({
        studentPopulations: [],
        personalCharacteristics: [],
        schoolEnvironment: [],
    });
    const [loading, setLoading] = useState(true);
    const [hasModuleProgress, setHasModuleProgress] = useState(null);

    const {
        widgets: {
            reportTitle: {
                props: {
                    settings: { moduleId, moduleTitle },
                },
            },
            reportIntro: {
                props: {
                    settings: { reportIntro },
                },
            },
        },
    } = props;

    useEffect(() => {
        getData();
    }, []);

    async function getData() {
        const response = await client.query({
            query: responsesQuery,
            fetchPolicy: 'network-only',
            variables: {
                pawnId,
            },
        });

        const data = response.data.flows.assignmentProgress.devTagsLists;

        if (!data.StudentPopulations || !data.PersonalCharacteristics || !data.SchoolEnvironment) {
            setHasModuleProgress(false);
        }

        if (data.StudentPopulations) {
            setHasModuleProgress(true);
            const studentPopResponses = data.StudentPopulations.filter(e => e);
            const personalCharResponses = data.PersonalCharacteristics
                ? data.PersonalCharacteristics.filter(e => e)
                : [];
            const schoolEnvResponses = data.SchoolEnvironment ? data.SchoolEnvironment.filter(e => e) : [];

            setReportSettings({
                studentPopulations: studentPopResponses,
                personalCharacteristics: personalCharResponses,
                schoolEnvironment: schoolEnvResponses,
            });
        }

        setLoading(false);
    }

    const postItNote = (
        <>
            <img alt="note" src={note} className={style.note} />
        </>
    );

    if (loading) {
        return <Loader loading type="inline" />;
    }

    return (
        <div style={{ width: '840px', margin: '0 auto', maxWidth: '100%' }}>
            <Report downloadFilename="STAT_Module_2_Report">
                <Page>
                    <div className={style.page}>
                        <ReportHeader moduleId={moduleId} moduleTitle={moduleTitle} />
                        <div className={style.reportIntro}>{reportIntro}</div>
                        {hasModuleProgress ? (
                            <div className={style.board}>
                                {postItNote}
                                <div className={style.reportBody}>
                                    <div className={style.label}>
                                        <img alt="exclamation" src={exclamation} className={style.icon} />
                                        <div style={{ height: 'min-content', alignSelf: 'center' }}>
                                            Student Populations
                                        </div>
                                    </div>
                                    <div className={style.responses}>
                                        {reportSettings.studentPopulations.map((resp, i) => {
                                            return (
                                                <div className={style.response} key={i}>
                                                    <span>{resp}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.label}>
                                        <img alt="dna" src={dna} className={style.icon} />
                                        <div style={{ height: 'min-content', alignSelf: 'center' }}>
                                            Personal Character Traits
                                        </div>
                                    </div>
                                    <div className={style.responses}>
                                        {reportSettings.personalCharacteristics.map((resp, i) => {
                                            return (
                                                <div className={style.response} key={i}>
                                                    <span>{resp}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={style.label}>
                                        <img alt="bag" src={bag} className={style.icon} />
                                        <div style={{ height: 'min-content', alignSelf: 'center' }}>
                                            School Environment
                                        </div>
                                    </div>
                                    <div className={style.responses}>
                                        {reportSettings.schoolEnvironment.map((resp, i) => {
                                            return (
                                                <div className={style.response} key={i}>
                                                    <span>{resp}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            renderAlert('Please finish the required questions to see this report')
                        )}
                        <ReportFooter />
                    </div>
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
    widgetsLoaded: PropTypes.array,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    widgets,
})(Component);
