import React from 'react';
import style from './style.css';

const ReportFooter = () => {
    return (
        <div className={style.container}>
            <div className={style.tagline}>
                Support for Teachers Affected By Trauma &copy; {new Date().getFullYear()}
            </div>
            <div className={style.copyright}>3C Institute</div>
        </div>
    );
};

export default ReportFooter;
